<template>
  <div id="setup">
    <AppLoader v-if="isLoading" />
    <RouterView />
  </div>
</template>
<script>
import AppLoader from "../../components/AppLoader";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AppLoader,
  },
  data() {
    return {
      next: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["guardian"]),
  },
  methods: {
    Next() {
      this.next = true;
    },
    noNext() {
      this.next = false;
    },
    ...mapActions(["fetchUser", "setWizardStepAction", "fetchProfile"]),
  },
  async created() {
    return await Promise.all([this.fetchUser()]).then(() => {
      setTimeout(()=> {
        this.isLoading = false;
      }, 1000)
    });
  },
  mounted() {
    const familyPath = `/guardian/setup/family`;
    // const childrenPath = `/setup/children`;
    // const missionsPath = `/setup/missions`;
    // const moneyPath = `/setup/money`;
    const basePath = `/guardian/setup/`;
    const basePathSimple = `/guardian/setup`;
    const indexPath = `/guardian/setup`;

    if (JSON.parse(localStorage.getItem("guardian_family_id")) != 0) {
      if (
        this.$route.path === familyPath ||
        this.$route.path === basePath ||
        this.$route.path === indexPath ||
        this.$route.path === basePathSimple
      ) {
        // this.$router.replace("/guardian/setup/children");
        this.$router.replace("/guardian");

      }
    } else {
      return false;
    }

    // if (this.$route.path !== familyPath || this.$route.path !== basePath || this.$route.path !== indexPath) {
    //   this.setWizardStepAction(1)
    // } else if (this.$route.path !== childrenPath) {
    //   this.setWizardStepAction(2)
    // } else if (this.$route.path !== missionsPath) {
    //   this.setWizardStepAction(3)
    // } else if (this.$route.path !== moneyPath) {
    //   this.setWizardStepAction(4)
    // }

    // if (this.$store.state.startPage == 0) {
    //   location.replace(
    //     config.GUARDIAN_HOST +
    //     `/index?token=${this.$store.state.token}&userId=${this.$store.state.userId}`
    //   );
    // }
  },
};
</script>

<style lang="scss">
// #setup {
//   padding: 20px 20px;
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// }

@media screen and (max-width: 1200px) {
  #setup {
    padding: 20px;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 820px) {
  .appChat,
  .setupSummary__title {
    display: none;
  }
  .setupSummary__stepsItemTitle {
    font-size: 16px;
  }
  .setupSummary__stepsItemDescription {
    font-size: 12px;
  }
  .setupSummary {
    min-height: auto;
  }
  .setupMain {
    position: relative;
    // border-radius: 20px;
    border-radius: 0;
    padding: 0;
  }
  #setup {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    padding: 20px;
  }
}
</style>
