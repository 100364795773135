<template>
  <div class="sonik-loader-container">
    <div class="sonik-loader"></div>
  </div>
</template>

<script>
  export default {
    name: "AppLoader",
    props: {
      alternate: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss">
  .sonik-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    width: 100%;
    height: 100vh;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sonik-loader {
    --x: 1s;
    height: 70px;
    width: 57px;
    min-height: 70px;
    min-width: 57px;
    animation: loaderSpin var(--x) linear infinite,
      loaderChange calc(4 * var(--x) / 2) infinite linear calc(var(--x) / 4);
    background-size: contain;
  }

  @keyframes loaderChange {
    0%,
    25% {
      background-image: url("../assets/images/learn.svg");
    }
    25.01%,
    50% {
      background-image: url("../assets/images/earn.svg");
    }
    50.01%,
    75% {
      background-image: url("../assets/images/save.svg");
    }
    75.01%,
    100% {
      background-image: url("../assets/images/spend.svg");
    }
  }

  @keyframes loaderSpin {
    100% {
      transform: rotateY(360deg);
    }
  }

  // .loading-screen {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100vh;
  //   background-color: #ffffff;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   text-align: center;
  //   z-index: 9999999;
  // }

  // .loading-screen-transparent {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100vh;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   text-align: center;
  //   z-index: 999999;
  // }

  // .logo {
  //   width: 120px;
  //   height: 60px;
  //   filter: invert(100%);
  // }

  // .loading-bar {
  //   width: 130px;
  //   height: 2px;
  //   background: #cfcfcf;
  //   margin-top: 22px;
  //   position: relative;
  //   overflow: hidden;
  // }

  // .loading-bar::before {
  //   content: "";
  //   width: 68px;
  //   height: 2px;
  //   background: #3ea4c0;
  //   position: absolute;
  //   left: -34px;
  //   animation: bluebar 1.5s infinite ease;
  // }

  // @keyframes bluebar {
  //   50% {
  //     left: 96px;
  //   }
  // }

  // .spinner {
  //   width: 56px;
  //   height: 56px;
  //   border-radius: 50%;
  //   background: radial-gradient(farthest-side, #ffffff 94%, #0000) top/9px 9px
  //       no-repeat,
  //     conic-gradient(#0000 30%, #ffffff);
  //   -webkit-mask: radial-gradient(
  //     farthest-side,
  //     #0000 calc(100% - 9px),
  //     #000 0
  //   );
  //   animation: spinner-c7wet2 1s infinite linear;
  // }

  // @keyframes spinner-c7wet2 {
  //   100% {
  //     transform: rotate(1turn);
  //   }
  // }

  // .loader {
  //   margin: 0 auto;
  //   font-size: 10px;
  //   width: 13px;
  //   height: 13px;
  //   border-radius: 50%;
  //   position: relative;
  //   text-indent: -9999em;
  //   -webkit-animation: load5 1.1s infinite ease;
  //   animation: load5 1.1s infinite ease;
  //   -webkit-transform: translateZ(0);
  //   -ms-transform: translateZ(0);
  //   transform: translateZ(0);
  // }
  // @-webkit-keyframes load5 {
  //   0%,
  //   100% {
  //     box-shadow: 0em -2.6em 0em 0em #3ea5c0,
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.5),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.7);
  //   }
  //   12.5% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.7),
  //       1.8em -1.8em 0 0em #3ea5c0, 2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.5);
  //   }
  //   25% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.5),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.7), 2.5em 0em 0 0em #3ea5c0,
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   37.5% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.5),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.7), 1.75em 1.75em 0 0em #3ea5c0,
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   50% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.5),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.7), 0em 2.5em 0 0em #3ea5c0,
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   62.5% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.5),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.7), -1.8em 1.8em 0 0em #3ea5c0,
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   75% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.5),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.7), -2.6em 0em 0 0em #3ea5c0,
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   87.5% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.5),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.7), -1.8em -1.8em 0 0em #3ea5c0;
  //   }
  // }
  // @keyframes load5 {
  //   0%,
  //   100% {
  //     box-shadow: 0em -2.6em 0em 0em #3ea5c0,
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.5),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.7);
  //   }
  //   12.5% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.7),
  //       1.8em -1.8em 0 0em #3ea5c0, 2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.5);
  //   }
  //   25% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.5),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.7), 2.5em 0em 0 0em #3ea5c0,
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   37.5% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.5),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.7), 1.75em 1.75em 0 0em #3ea5c0,
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   50% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.5),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.7), 0em 2.5em 0 0em #3ea5c0,
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   62.5% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.5),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.7), -1.8em 1.8em 0 0em #3ea5c0,
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   75% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.5),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.7), -2.6em 0em 0 0em #3ea5c0,
  //       -1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2);
  //   }
  //   87.5% {
  //     box-shadow: 0em -2.6em 0em 0em rgba(62, 165, 192, 0.2),
  //       1.8em -1.8em 0 0em rgba(62, 165, 192, 0.2),
  //       2.5em 0em 0 0em rgba(62, 165, 192, 0.2),
  //       1.75em 1.75em 0 0em rgba(62, 165, 192, 0.2),
  //       0em 2.5em 0 0em rgba(62, 165, 192, 0.2),
  //       -1.8em 1.8em 0 0em rgba(62, 165, 192, 0.5),
  //       -2.6em 0em 0 0em rgba(62, 165, 192, 0.7), -1.8em -1.8em 0 0em #3ea5c0;
  //   }
  // }
</style>
